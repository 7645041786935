import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Table, Button, Container, Row, Col } from "reactstrap";
import ModalForm from "./Modals/Modal";

import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import AuthService from "../services/auth.service";
import authHeader from "../services/auth-header";

const API_URL = process.env.REACT_APP_API_URL || "http://192.168.1.16:3001";

const BoardAdmin = () => {
  const [content, setContent] = useState([]);
  const navigate = useNavigate();
  //const items = [];

  useEffect(() => {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      navigate("/login");
    }
    UserService.getAdminBoard().then(
      (response) => {
        // console.log(response);
        //items = response.data;

        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
    // eslint-disable-next-line
  }, []);

  const addItemToState = (item) => {
    console.log("addItemToState", item);
    // setContent(prevState => ({
    //   items: [...content, item]
    // }))
  };
  // return (
  //   <div className="container">
  //     <header className="jumbotron">
  //       <h3>{content}</h3>
  //     </header>
  //   </div>
  // );

  const handleDelete = (e) => {
    let headerRow = authHeader();
    headerRow["Content-Type"] = "application/json";

    fetch(API_URL + "/api/admin/deletetecompany", {
      method: "delete",
      headers: headerRow,
      body: JSON.stringify({
        id: e,
      }),
    }).then(async (response) => {
      const data = await response.json();
      console.log("deleted item is", data);
      window.location.reload();
    });
  };

  return (
    <Container>
      <Row>
        <Col>
          <Table responsive hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Company</th>
                <th>Email</th>
                <th>Key</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {content.map((item, index) => (
                <tr key={index + 1}>
                  <th scope="row"> {item.id}</th>
                  <td>{item.company_name}</td>
                  <td>{item.email}</td>
                  <td>{item.company_key}</td>
                  <td>{item.status}</td>
                  <td>
                    <div style={{ width: "130px" }}>
                      <ModalForm
                        buttonLabel="Edit"
                        id={item.id}
                        company_name={item.company_name}
                        username={item.username}
                        email={item.email}
                        company_key={item.company_key}
                        mobile={item.mobile}
                        address={item.address}
                        status={item.status}
                      />{" "}
                      <Button
                        color="danger"
                        onClick={() => handleDelete(item.id)}
                      >
                        Del
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <ModalForm
            buttonLabel="Add Company"
            addItemToState={addItemToState}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default BoardAdmin;
