import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL || "http://192.168.1.16:3001";
const API_ADMIN_URL = API_URL+"/api/admin/"

class UserService {
  getPublicContent() {
    return axios.get(API_ADMIN_URL + 'all');
  }

  getUserBoard() {
    return axios.get(API_ADMIN_URL + 'user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_ADMIN_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_ADMIN_URL + 'company', { headers: authHeader() });
  }
}

export default new UserService();