import React from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import authHeader from "../../services/auth-header";
//import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
const API_URL = process.env.REACT_APP_API_URL || "http://192.168.1.16:3001";

class AddEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      username: props.username,
      company_name: props.company_name,
      email: props.email,
      address: props.address,
      mobile: props.mobile,
      company_key: props.company_key,
      status: props.status,
      api_url: API_URL + "/api/",
    };
  }
  state = {
    id: 0,
    company_name: "",
    username: "",
    email: "",
    mobile: "",
    company_key: "",
    address: "",
    status: "active",
    dropdownOpen: false,
    api_url: API_URL + "/api/",
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  toggle = (statusType = "") => {
    console.log("statusType", statusType);
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
      //   status: statusType || 'active'
    });
    console.log("dropdownOpen", this.state.dropdownOpen);
  };
  submitFormAdd = (e) => {
    let headerRow = authHeader();
    headerRow["Content-Type"] = "application/json";

    e.preventDefault();
    fetch(this.state.api_url + "admin/company", {
      method: "post",
      headers: headerRow,
      body: JSON.stringify({
        company_name: this.state.company_name,
        mobile: this.state.mobile,
        email: this.state.email,
        username: this.state.username,
        address: this.state.address,
        status: this.state.status,
      }),
    })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => console.log("error is: ", err));
  };

  submitFormEdit = (e) => {
    let headerRow = authHeader();
    headerRow["Content-Type"] = "application/json";
    e.preventDefault();
    fetch(this.state.api_url + "admin/company", {
      method: "put",
      headers: headerRow,
      body: JSON.stringify({
        id: this.state.id,
        company_name: this.state.company_name,
        mobile: this.state.mobile,
        address: this.state.address,
        status: this.state.status,
      }),
    }).then(async (response) => {
      const data = await response.json();
      // console.log(data);
      if (data.status === "ok") {
        this.props.toggle();
        window.location.reload();
      }
    });
  };

  componentDidMount() {
    // if item exists, populate the state with proper data
    if (this.props.item) {
      const { id, company_name, mobile, email, username, address, status } =
        this.props.item;
      this.setState({
        id,
        company_name,
        mobile,
        email,
        username,
        address,
        status,
      });
    }
  }

  render() {
    return (
      <Form onSubmit={this.state.id ? this.submitFormEdit : this.submitFormAdd}>
        <FormGroup>
          <Label for="company_name">Company Name</Label>
          <Input
            type="text"
            name="company_name"
            id="company_name"
            onChange={this.onChange}
            value={
              this.state.company_name === null ? "" : this.state.company_name
            }
          />
        </FormGroup>
        <FormGroup>
          <Label for="username">Username</Label>
          <Input
            type="text"
            name="username"
            id="username"
            onChange={this.onChange}
            value={this.state.username === null ? "" : this.state.username}
          />
        </FormGroup>
        <FormGroup>
          <Label for="email">Email</Label>
          <Input
            type="email"
            name="email"
            id="email"
            onChange={this.onChange}
            value={this.state.email === null ? "" : this.state.email}
          />
        </FormGroup>
        <FormGroup>
          <Label for="mobile">Mobile</Label>
          <Input
            type="text"
            name="mobile"
            id="mobile"
            onChange={this.onChange}
            value={this.state.mobile === null ? "" : this.state.mobile}
            placeholder="ex. 555-555-5555"
          />
        </FormGroup>
        <FormGroup>
          <Label for="address">Address</Label>
          <Input
            type="text"
            name="address"
            id="address"
            onChange={this.onChange}
            value={this.state.address === null ? "" : this.state.address}
            placeholder="City, State"
          />
        </FormGroup>
        <FormGroup>
          <Label for="status">Status</Label>
          <select
            name="status"
            className="form-control"
            id="status"
            onChange={this.onChange}
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
            <option value="deleted">Deleted</option>
          </select>
          <Input
            type="hidden"
            name="status"
            id="status"
            onChange={this.onChange}
            value={this.state.status}
          />
        </FormGroup>
        <Button>Submit</Button>
      </Form>
    );
  }
}

export default AddEditForm;
