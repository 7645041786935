import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://192.168.1.16:3001";
const API_AUTH_URL = API_URL + "/api/auth/";
class AuthService {
  login(username, password) {
    return axios
      .post(API_AUTH_URL + "signin", {
        username,
        password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, email, password) {
    return axios.post(API_AUTH_URL + "signup", {
      username,
      email,
      password,
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default new AuthService();
